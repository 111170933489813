.itemContent {
  padding: 20px;
  height: 100px;
  border-radius: 24px;
  background: #f8f7ff;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .itemContentLeft {
    display: flex;
    align-items: center;

    .imgContainer {
      width: 60px;
      height: 60px;
      border-radius: 30px;
    }

    h3 {
      margin-left: 20px;
      text-align: left;
      font-size: 20px;
      font-weight: bold;
      color: #0c0c0c;
      line-height: 28px;
    }
  }
}

@media screen and (max-width: 550px) {
  .itemContent {
    height: 150px;
    flex-direction: column;
    justify-content: flex-start;

    .itemContentLeft {
      margin-bottom: 10px;
    }
  }
}

