.modalContainer,
.plamModalContainer {
  max-width: 450px;
  text-align: center;

  :global {
    .next-antd-modal-footer {
      display: none;
    }
  }

  @media screen and (max-width: 550px) {
    max-width: calc(100% - 20px);
  }


  .formContent {
    text-align: center;

    .inputItem {
      height: 46px;
      border-radius: 0;
      border-left: 0;
      border-right: 0;
      border-top: 0;
      font-size: 16px;

      :hover {
        border-left: 0;
        border-right: 0;
        border-top: 0;
      }
    }

    .itemContent {
      padding: 20px;
      height: 100px;
      border-radius: 24px;
      background: #f8f7ff;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .itemContentLeft {
        display: flex;
        align-items: center;

        .imgContainer {
          width: 60px;
          height: 60px;
          border-radius: 30px;
        }

        h3 {
          margin-left: 20px;
          text-align: left;
          font-size: 20px;
          font-weight: bold;
          color: #0c0c0c;
          line-height: 28px;
        }
      }
    }

    .desc {
      font-size: 16px;
      color: #666666;
    }

    .weekContainer {
      >.title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
      }

      >.weekContent {
        display: flex;
        justify-content: center;
        align-items: center;

        .minus,
        .plus {
          font-size: 30px;
          cursor: pointer;
          color: var(--other-text-color);
        }

        .disAble {
          cursor: auto;
          color: #999;
        }

        >.descContainer {
          margin-left: 10px;
          margin-right: 10px;

          >.weekStyle {
            font-size: 20px;
            font-weight: bold;
          }

          >.dateStyle {
            font-size: 16px;
            font-weight: 400;
            color: var(--app-text-60-color);
          }
        }
      }
    }

    .bottomButton {
      min-width: 300px;
      height: 46px;
      margin-top: 10px;
      margin-right: 10px;
      text-align: center;
      border-radius: 23px;
      padding: 0 50px;
      cursor: pointer;
    }

    :global {
      .next-antd-form-item .next-antd-form-item-label>label.next-antd-form-item-required:not(.next-antd-form-item-required-mark-optional)::before {
        display: none;
      }
    }
  }

}

.titleContainer {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 20px;
}

.fixedBottomBar {
  z-index: 1000;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #DFE2EE;
  // border-top: 1px solid #e5e5e5;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  .shareButton {
    padding: 8px;
    background: none;
    border: none;
    cursor: pointer;
    border-radius: 6px;
    background-color: #ffffff;
    color: #7E84A3;
    font-size: 14px;

    &:hover {
      background-color: #f3f4f6;
    }

  }


  .trackButton {
    flex: 1;
    padding: 8px 16px;
    background-color: #9333ea;
    color: #ffffff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    &:hover {
      background-color: #2563eb;
    }
  }

  .trackButton.disabled {
    background-color: #E5ECFF;
    color: #9333ea;
    cursor: not-allowed;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
}
