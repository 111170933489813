// 先定义变量
@animation-duration: 5s;

@white-color: white;
@start-opacity: 0;
@end-opacity: 1;

// 定义关键帧动画
.gradient-slide-animation() {
  // @keyframes gradient-slide {
  //   0% {
  //     transform: translateX(-100%);
  //     opacity: 0;
  //   }
  //   50% {
  //     opacity: 1;
  //   }
  //   100% {
  //     transform: translateX(100%);
  //     opacity: 0;
  //   }
  // }
  @keyframes gradient-slide {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    60% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(100%);
      opacity: 0;
    }
  }
}

.gradient-background-mixin() {
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    white,
    rgba(255, 255, 255, 0)
  );
}

// 创建按钮样式
.buttonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  background-color: var(--app-primary-color);
  color: var(--other-text-color);
  cursor: pointer;
  overflow: hidden;

  > .content {
    padding: 8px 10px;
    width: 100%;
    height: 100%;
  }

  & > .gradientBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
    .gradient-background-mixin();

    // background-image: linear-gradient(to right, @gradient-color-start, @gradient-color-end);
    // animation: ~'gradient-slide @{animation-duration} ease-in-out forwards';
    animation: gradient-slide 5s infinite;
  }
}

// 使用关键帧动画
.gradient-slide-animation();

