.title {
    font-family: Inter, Inter;
    font-weight: bold;
    font-size: 28px;
    color: #000000;
    line-height: 33px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    padding-top: 20px;
}

.infoContent {
    margin-top: 16px;
    margin-bottom: 35px;

    >.desc {
        font-family: Inter, Inter;
        font-weight: 400;
        font-size: 16px;
        color: #666666;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    >.list {
        margin-top: 20px;
        background: #F1EFFF;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 20px;
    }
}
