.modalContainer {
    max-width: 450px;
    :global {
      .next-antd-modal-footer {
        display: none;
      }
    }
  }


.modalContent {
    // background-color: linear-gradient( 180deg, #DDD4FF 0%, #FFFFFF 26%);

    // background-image: url('https://res-front.pumpsoul.com/ins-prod/image/unlock-top-bg.webp');
    // background-size:  100% auto;
    // background-position: top center;

    background-image: url('https://res-front.pumpsoul.com/ins-prod/image/unlock_bg_2.webp');
    background-size: cover;
    background-position: bottom center;

    background-repeat: no-repeat;
    border-radius: 0.5rem;
    padding: 1.25rem;

    .header {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 1rem;
    
        .profileSection {
          display: flex;
          align-items: center;
          gap: 12px;
          margin-right: 2rem;
    
          .text {
            font-size: 16px;
            font-weight: 700;
          }
        }
      }
    
      .content {
        // max-height: 400px;
        overflow-y: auto;
        padding: 1.5rem 1rem;
        box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.08);
        border-radius: 12px;
        border: 1px solid #E3E6F3;
        background: #FFF;
    
        .section {
          display: flex;
          align-items: flex-start;
          gap: 0.75rem;
          margin-bottom: 5px;
    
          .dot {
            width: 8px;
            height: 8px;
            max-width: 8px;
            max-height: 8px;
            min-width: 8px;
            min-height: 8px;
            border-radius: 50%;
            margin-top: 8px;
    
            &.purple { background-color: #8b5cf6; }
            &.yellow { background-color: #fbbf24; }
            &.red { background-color: #ef4444; }
            &.green { background-color: #10b981; }
            &.blue { background-color: #3b82f6; }
          }
    
          .sectionContent {
            h4 {
              font-weight: 500;
              margin-bottom: 4px;
            }
    
            .description {
              font-size: 14px;
              color: #6b7280;
            }
    
            ul {
              list-style-type: none;
              padding: 0;
              margin: 0;
    
              li {
                font-size: 14px;
                color: #6b7280;
                margin-bottom: 8px;
              }
            }
          }
        }
      }

      .title1 {
        margin-top: 24px;
        font-family: Inter, Inter;
        font-weight: bolder;
        font-size: 24px;
        color: #0C0C0C;
        line-height: 24px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }

      .title2 {
        margin-top: 24px;

        font-family: Inter, Inter;
        font-weight: 400;
        font-size: 20px;
        color: #0C0C0C;
        line-height: 20px;
        text-align: left;
        font-style: normal;
        text-transform: none;

        >:nth-child(2) {
          color: #725CFF;
        }

      }

      .title3 {
        margin-top: 24px;
        margin-bottom: 200px;

        font-family: Inter, Inter;
        font-weight: 400;
        font-size: 20px;
        color: #0C0C0C;
        line-height: 20px;
        text-align: left;
        font-style: normal;
        text-transform: none;

        @media screen and (max-width:768px) {
          margin-bottom: 110px;
        }
      }
    
      .actionButton {
        margin: 0 auto;

        border-radius: 0.5rem;
        text-align: center;
        margin-top: 1rem;
        // width: 60%;
        background: #8b5cf6;
        color: white;
        min-height: 3rem;
    
        &:hover {
          background: #7c3aed;
        }
      }
}
